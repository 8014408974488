import * as React from "react";

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import PageTitle from '../components/PageTitle';
import ContactForm from "../components/ContactForm";
import TitleSection from "../components/TitleSection";
import LocationOpeningTimes from "../components/LocationOpeningTimes";

const Contact = () => (
  <Layout>
    <Seo title="Contact" />
    <PageTitle title={'Contact'}/>

    <TitleSection title={"Contact Adrian Rowe Haircutters"}/>

    <ContactForm/>
    <LocationOpeningTimes/>

  </Layout>
)

export default Contact;
