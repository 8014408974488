import React, { useState } from "react";
import { navigate } from "gatsby";


function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


const ContactForm = () => {

  const [state, setState] = useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => console.log(error));
  }

  return (
    <section>
      <div className={`relative max-w-6xl mx-auto px-4 sm:px-6`}>
        <div className={`flex flex-col lg:flex-row lg:space-x-12`}>

          <div className={`lg:w-1/3 text-xl font-normal space-y-4 mb-8 lg:mb-0`}>
            <p>Please get in touch with us if you have any questions, or to book an appointment.</p>
            <p>We look forward to hearing from you!</p>
          </div>

          <div className={`lg:w-2/3 bg-gray-50 p-12`}>

            <form name="contact-form"
                  method="POST"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                  action={"/thanks"}
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact-form" />

              <div className={`flex flex-col items-start space-y-4`}>
                <h3 className={`uppercase text-lg font-bold`}>Your Details<span className={`text-red`}>.</span></h3>

                <input
                  className={`w-full py-4 border-transparent placeholder:text-gray placeholder:uppercase focus:ring-red focus:border-red transition duration-300 ease-in-out`}
                  type={'text'}
                  placeholder={'Your Name'}
                  name={'name'}
                  required
                />

                <input
                  className={`w-full py-4 border-transparent placeholder:text-gray placeholder:uppercase focus:ring-red focus:border-red transition duration-300 ease-in-out`}
                  type={'email'}
                  placeholder={'Your Email'}
                  name={'email'}
                  required
                />

                <input
                  className={`w-full py-4 border-transparent placeholder:text-gray placeholder:uppercase focus:ring-red focus:border-red transition duration-300 ease-in-out`}
                  type={'tel'}
                  placeholder={'Your Telephone'}
                  name={'phone'}
                />

                <textarea
                  rows={5}
                  className={`w-full py-4 border-transparent placeholder:text-gray placeholder:uppercase focus:ring-red focus:border-red transition duration-300 ease-in-out`}
                  placeholder={'Your message / enquiry'}
                  name={'enquiry'}
                  required
                />

                <button
                  className={`w-auto flex flex-row group text-black px-6 py-2 border-black border border-solid hover:bg-red hover:border-red transition ease-in-out duration-300`}>
                  <span
                    className={`uppercase group-hover:text-white transition ease-in-out duration-300 mr-1`}>Submit</span>

                  <svg className={`w-6 h-6 text-red group-hover:text-white transition ease-in-out duration-300`}
                       fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </button>

              </div>
            </form>

          </div>

        </div>

      </div>
    </section>
  )
}

export default ContactForm;